import React, { useState } from "react";
import { isMobile, isMobileSafari, isAndroid } from "react-device-detect";

import { Flex, Image, Stack, Button } from "Atoms";
import { modalNames } from "Constants";
import { useLanguage, useModal } from "Providers";
import { LanguageHeader, LanguageText } from "Components";
import { SwishPaymentProps } from "../PaymentTypeWrapper";
import { useEffectOnce } from "Hooks";
import { LoadingMessage } from "TempUtils";

const SWISH_ONGOING_TRANSACATION_URL_PARAM = "ongoingTransaction";

export const SwishOnlinePayment = ({ qrCode, token, cancelMutation, goBack }: SwishPaymentProps) => {
    const { openModal } = useModal();
    const { translate } = useLanguage();
    const isReturningFromSwish = window.location.search.includes(SWISH_ONGOING_TRANSACATION_URL_PARAM);
    const [isPaymentStarted, setIsPaymentStarted] = useState(
        localStorage.getItem(`${token}-opened`) == "true" || isReturningFromSwish
    );
    const [shouldShowCancelPaymentButton, setShouldShowCancelPaymentButton] = useState(false);

    /*
     Possible improvement:
      If want the redirect to happen quicker after the Swish payment, we can check the platform.
      It's possible to speed things up a bit on android

      If Android, DO NOT send a `callbackurl` to the Swish app. Android will automatically redirect back to the browser if we don't
      If iOS, we MUST use an valid "https://" address.  iOS has no support for "going back" to the browser
       or to the previous webpage.  Therefore, the order-status page needs to be passed as the `callbackurl`.
       iOS will open this page in a new browser window after the Swish payment is complete.
    */

    // Add a recognizable query param to the callback url
    // This allows us to know that we are returning from the Swish app
    const callbackUrl = new URL(window.location.href);
    const urlParams = new URLSearchParams(callbackUrl.search);
    urlParams.set(SWISH_ONGOING_TRANSACATION_URL_PARAM, "true");
    callbackUrl.search = urlParams.toString();

    const isInIFrame = window !== window.parent;

    // callbackUrl is the current href with "ongoingTransaction=true" appended to the query params
    const encodedCallbackUrl = encodeURIComponent(callbackUrl.toString());
    let openSwishURI = `swish://paymentrequest?token=${token}&callbackurl=${encodedCallbackUrl}`;
    if (isAndroid) {
        // Previously, we assumed "merchant://" would work because of the documentation, however
        //  it seems that "merchant://" is just a doc example and not a real URI scheme.  Additionally, the example was used by
        //  the popular tanning bed application in Sweden.  Our callback was opening that application instead of the browser.
        openSwishURI = `swish://paymentrequest?token=${token}`;
    }

    const shouldOpenSwishAutomatically = isMobile && !!token;
    useEffectOnce(() => {
        // We need additional logic in our useEffectOnce, because the initial render triggers when switching back to the browser
        // If we don't remember that we triggered Swish for this payment already, we can never get back to this page
        //  without Swish trying to open automatically
        if (shouldOpenSwishAutomatically && !isPaymentStarted) {
            setIsPaymentStarted(true);
            /**
             * Setup timer to detect whether the Swish app has been opened or not
             */
            const showPaymentButtonAndQRTimeout = setTimeout(
                async () => {
                    // We stop spinning and wait for the user to manually open Swish
                    setIsPaymentStarted(false);
                },
                // Since swish does not auto open for safari users, let them see the qr and buttons directly
                isMobileSafari ? 0 : 5000
            );

            const showCancelPaymentButtonTimeout = setTimeout(async () => {
                setShouldShowCancelPaymentButton(true);
            }, 10000);

            // We are assuming that the user has opened Swish
            // The fault in assuming this, is that the user might have opened another app, or the phone might have gone to sleep
            // Unfortunately, there is no way to detect that the user has opened Swish specifically
            window.onblur = () => {
                localStorage.setItem(`${token}-opened`, "true");

                clearTimeout(showPaymentButtonAndQRTimeout);
                clearTimeout(showCancelPaymentButtonTimeout);
            };

            // Try to open Swish
            window.location.replace(openSwishURI);
        }
    });

    const openSwish = () => {
        // If iframed AND iOS safari, then use the parent location redirect
        if (isMobileSafari && isInIFrame) {
            parent.location.href = openSwishURI;
        } else {
            window.location.replace(openSwishURI);
        }
    };

    // We don't want to render anything here since we assume we are returning from the Swish app
    // removed spinner for mobile safari users since this caused issues when coming back from aborted payment
    if (isPaymentStarted && !(isMobileSafari && !!qrCode)) {
        return <LoadingMessage />;
    }

    return (
        <Flex direction="column" align="center" justify="center">
            <Stack p={8} my={8} mx={2} h="auto" boxShadow="lg" borderRadius="md" bg={"white"} alignItems="center">
                <LanguageHeader tid="completePaymentThroughSwish" as="h4" size="lg" textAlign="center" />
                {!!qrCode && (
                    <Image
                        id="testSwishQRCode"
                        src={`data:image/svg+xml;base64,${qrCode}`}
                        alt=""
                        width="3xs"
                        maxHeight="400px"
                    />
                )}
                {isMobile && (
                    <Button themeColor="blue" fullWidth size="lg" rounded="md" onClick={openSwish}>
                        {translate("openSwish")}
                    </Button>
                )}
                {/* Show cancel payment button on Desktop always and on mobile Safari */}
                {(!isMobile || shouldShowCancelPaymentButton || isMobileSafari) && (
                    <Button
                        themeColor="red"
                        fullWidth
                        my={2}
                        variant="outline"
                        color="red.500"
                        size="lg"
                        rounded="md"
                        onClick={() =>
                            isMobileSafari
                                ? openModal(modalNames.CONFIRM_CANCEL_PAYMENT_MODAL, { cancelMutation })
                                : goBack()
                        }
                    >
                        {translate("cancelOrder")}
                    </Button>
                )}
                <LanguageText tid="receiverQopla" as="span" textAlign="center" m={0} />
            </Stack>
        </Flex>
    );
};
