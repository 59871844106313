import React, { useEffect } from "react";
import { BiReceipt } from "@react-icons/all-files/bi/BiReceipt";
import moment from "moment";
import { Link as RouterLink } from "react-router-dom";

import { Box, Header, Text, Flex, NewList, NewListItem, NewDivider, Link } from "Atoms";
import { formatSwedishFinancialNumbers, getCateringPickupText } from "TempUtils";
import { formatShopUrlName } from "Utils";
import { OnlineAddonsList, OnlineBundleItemsList } from "Organisms";
import { usePos, useOnline, useLanguage, useQopla } from "Providers";
import { SwishOrderResponse } from "../OrderStatusContainer";
import { CustomerModificationsList } from "./CustomerModificationsList";
import { LanguageText, LanguageHeader } from "Components";
import { WebOrderType } from "Types";
import { failedOnlineOrderStatuses } from "Constants";
import { formatDeniedOrderMsg } from "../orderStatusUtils";
import { useOrderWindowsStore } from "Stores";

type Props = {
    order: SwishOrderResponse;
};

export const CateringOrder: React.FC<Props> = ({ order }) => {
    const { emptyCart, activeOrderWindowId } = useOrderWindowsStore();
    const { selectedShop: shop } = useQopla();
    const { resetFoodOptions } = useOnline();
    const { translate } = useLanguage();

    useEffect(() => {
        emptyCart(activeOrderWindowId);
        resetFoodOptions();
    }, []);

    const {
        userInformation: { onlineContactInformation: userInfo },
        contactInformation: shopInfo,
        onlineOrderStatus
    } = order;

    const isFailedOrder = failedOnlineOrderStatuses.includes(onlineOrderStatus);

    const isCateringHomeDelivery = order.webOrderType === WebOrderType.CATERING_DELIVERY;
    const titleKey = isCateringHomeDelivery ? "deliveryDate" : "pickupDate";

    let pickupTime = getCateringPickupText(order.timeInterval ?? null, order.pickupTime, shop!, false);

    return (
        <Flex direction="column" align="center">
            <Box mb={3}>
                <Box as={BiReceipt} size="60px" />
            </Box>
            <Box bg="white" p="6" rounded="md" borderWidth={1} borderRadius={8} boxShadow="lg" minW="35vw">
                <Header size="xl" textAlign="center">
                    {translate("hello")} {userInfo.name}!
                </Header>
                {isFailedOrder ? (
                    <Flex direction="column" align="center" h="auto">
                        <Text>
                            {translate("errorMessage")}: {translate(formatDeniedOrderMsg(onlineOrderStatus))}
                        </Text>
                        {shop?.publicId && (
                            <RouterLink
                                style={{ color: "#F56565" }}
                                to={`/restaurant/${formatShopUrlName(order.shopName)}/${shop.publicId}/checkout`}
                            >
                                {translate("tryAgain")}
                            </RouterLink>
                        )}
                    </Flex>
                ) : (
                    <>
                        <LanguageText tid="yourCateringOrder" fontSize="xl" mb={6} textAlign="center" />
                        <Header size="lg" color="gray.600" mt="0" fontWeight="normal" textAlign="center">
                            {translate(titleKey)}: {pickupTime}
                        </Header>

                        <Text color="red.500" h="auto" mb={12} maxW="xs" mx="auto" textAlign="center">
                            * {translate("restaurantMustConfirm")} {translate("aMailWillBeSentTo")} {userInfo.email}{" "}
                            {translate("ifOrderWillBeConfirmed")}.
                        </Text>
                    </>
                )}
                <NewDivider color="gray.500" my={6} mx="auto" />
                <Flex h="auto" justify="space-between" direction={["column", "column", "row"]}>
                    <Box mb={[4, 4, 0]}>
                        <LanguageHeader
                            tid="restaurantDetails"
                            size="lg"
                            textDecoration="underline"
                            color="gray.600"
                            fontWeight="normal"
                            textAlign="left"
                            mb={4}
                        />
                        <Flex px={4} direction="column">
                            <Text fontSize="lg" mb={2}>
                                {translate("phone")}:
                                <Link href={`tel:${shopInfo.phoneNumber}`} color="green.500" ml={1}>
                                    {shopInfo.phoneNumber}
                                </Link>
                            </Text>
                            <Text fontSize="lg" mb={2}>
                                {translate("mail")}:
                                <Link href={`mailto:${shopInfo.email}`} color="green.500" ml={1}>
                                    {shopInfo.email}
                                </Link>
                            </Text>
                            <Text fontSize="lg" m="0">
                                {translate("address")}: {shopInfo.addressLine}
                            </Text>
                        </Flex>
                    </Box>
                    <Box>
                        <LanguageHeader
                            tid="yourDetails"
                            size="lg"
                            textDecoration="underline"
                            color="gray.600"
                            fontWeight="normal"
                            textAlign="left"
                            mb={4}
                        />
                        <Flex px={4} direction="column">
                            <Text fontSize="lg" mb={2}>
                                {translate("name")}: {userInfo.name}
                            </Text>
                            <Text fontSize="lg" mb={2}>
                                {translate("phone")}: {userInfo.phoneNumber}
                            </Text>
                            <Text fontSize="lg" mb={2}>
                                {translate("mail")}: {userInfo.email}
                            </Text>
                            {isCateringHomeDelivery && (
                                <Flex direction="column" height="auto">
                                    <Text fontSize="lg" mb={1}>
                                        {translate("address")}:
                                    </Text>
                                    <Flex direction="column" ml={4}>
                                        <Text as="span" fontSize="lg">
                                            {userInfo.addressLine}
                                        </Text>
                                        <Text as="span" fontSize="lg">
                                            {userInfo.postCode}, {userInfo.city}
                                        </Text>
                                    </Flex>
                                </Flex>
                            )}
                        </Flex>
                    </Box>
                </Flex>
                <NewDivider color="gray.500" my={6} mx="auto" />
                <Box>
                    <LanguageHeader
                        tid="products"
                        size="lg"
                        textDecoration="underline"
                        color="gray.600"
                        mt="0"
                        fontWeight="normal"
                        textAlign="left"
                    />
                    <NewList my={4} px={4} stretch={6}>
                        {order.orderProducts.map((orderProduct, i) => (
                            <NewListItem display="flex" flexDirection="column" key={i}>
                                <Flex justify="space-between" mb={3}>
                                    <Text fontSize="lg" m="0">
                                        {orderProduct.quantity}x {orderProduct.name}
                                    </Text>
                                    <Text>{formatSwedishFinancialNumbers(orderProduct.totalPrice)}</Text>
                                </Flex>
                                <OnlineAddonsList mb={2} addons={orderProduct.addons} />
                                <OnlineBundleItemsList selectedBundleItems={orderProduct.selectedBundleProductItems} />
                                {!!orderProduct.modifications && (
                                    <CustomerModificationsList
                                        modifications={orderProduct.modifications}
                                        productPrice={orderProduct.totalPrice}
                                    />
                                )}
                            </NewListItem>
                        ))}
                    </NewList>
                </Box>
                {!!order.comment && (
                    <Box mb={6}>
                        <LanguageHeader
                            tid="messageToShop"
                            size="lg"
                            textDecoration="underline"
                            color="gray.600"
                            mt="0"
                            fontWeight="normal"
                            textAlign="left"
                        />
                        <Text fontSize="lg" mb={2} px={4}>
                            {order.comment}
                        </Text>
                    </Box>
                )}
                {!!order.comment2 && (
                    <Box mb={6}>
                        <LanguageHeader
                            tid="messageToCourier"
                            size="lg"
                            textDecoration="underline"
                            color="gray.600"
                            mt="0"
                            fontWeight="normal"
                            textAlign="left"
                        />
                        <Text fontSize="lg" mb={2} px={4}>
                            {order.comment2}
                        </Text>
                    </Box>
                )}

                <Box mt={12}>
                    <Flex justify="space-between">
                        <Text fontSize="3xl" m="0" fontWeight="bold">
                            {translate("total")}:
                        </Text>
                        <Text fontSize="3xl" m="0" fontWeight="bold">
                            {formatSwedishFinancialNumbers(order.totalAmount)}
                        </Text>
                    </Flex>
                </Box>
            </Box>
        </Flex>
    );
};
