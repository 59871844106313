import React, { useState, useEffect } from "react";
import { Popup } from "semantic-ui-react";
import { Helmet } from "react-helmet-async";
import { isMobileSafari } from "react-device-detect";
import qs from "query-string";

import {
    onlineOrderStatus,
    failedOnlineOrderStatuses,
    declinedOnlineOrderStatuses,
    KitchenOrderStatus
} from "Constants";
import { formatShopUrlName } from "Utils";
import { Flex, CardBody, Card, CardHeader, Link, Header, Text, NewGrid, RoutePrompt } from "Atoms";
import { LinkButton } from "Molecules";
import { useInterval } from "Hooks";
import { useQopla, useOnline, useLanguage, Languagekey, EatingPreference } from "Providers";
import { Steps, StepItem } from "Organisms";
import { SwishOrderResponse, OrderStatusTimestamps } from "../OrderStatusContainer";
import {
    formatDeniedOrderMsg,
    homeDeliverySteps,
    tableServiceSteps,
    deliverySteps,
    pickupTimeText,
    lastMileDeliverySteps,
    getOrderStepTimestamp
} from "../orderStatusUtils";
import { OrderSummary, OrderHeader } from "./Order/index";
import { LanguageHeader } from "Components";
import { useOnlineStore, useOrderWindowsStore } from "Stores";

type Props = {
    order: SwishOrderResponse;
    kitchenOrderStatusTimestamps: OrderStatusTimestamps;
    pickupDates: string[];
    orderDenyReason: string;
};

type Steps = {
    activeIndex: number;
    currentStatus: "process" | "error" | "success";
};

const getOrderSteps = (order: SwishOrderResponse) => {
    const isHomeDelivery = order.eatingOption === EatingPreference.HOME_DELIVERY;
    if (isHomeDelivery && !!order.deliveryCreateResponse) {
        return lastMileDeliverySteps;
    } else if (isHomeDelivery) {
        return homeDeliverySteps;
    } else if (order.tableMeta) {
        return tableServiceSteps;
    }
    return deliverySteps;
};

const thirdPartySteps = {
    WAITING: {
        activeIndex: 3,
        currentStatus: "process"
    },
    PROCESSING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    DONE_PREPPING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    DONE_COOKING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    CONFIRMED: {
        activeIndex: 2,
        currentStatus: "process"
    },
    READY_FOR_PICKUP: {
        activeIndex: 1,
        currentStatus: "process"
    },
    DELIVERED: {
        activeIndex: 1,
        currentStatus: "process"
    },
    CANCELLED: {
        activeIndex: 3,
        currentStatus: "error"
    },
    CANCELLED_PAYMENT: {
        activeIndex: 3,
        currentStatus: "error"
    }
};

const deliveryStepss = {
    WAITING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    PROCESSING: {
        activeIndex: 1,
        currentStatus: "process"
    },
    DONE_PREPPING: {
        activeIndex: 1,
        currentStatus: "process"
    },
    DONE_COOKING: {
        activeIndex: 1,
        currentStatus: "process"
    },
    READY_FOR_PICKUP: {
        activeIndex: 0,
        currentStatus: "success"
    },
    DELIVERED: {
        activeIndex: 0,
        currentStatus: "success"
    },
    CANCELLED: {
        activeIndex: 3,
        currentStatus: "error"
    },
    CANCELLED_PAYMENT: {
        activeIndex: 3,
        currentStatus: "error"
    }
};

const regularSteps = {
    PENDING: {
        activeIndex: 3,
        currentStatus: "process"
    },
    WAITING: {
        activeIndex: 3,
        currentStatus: "process"
    },
    PROCESSING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    DONE_PREPPING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    CONFIRMED: {
        activeIndex: 2,
        currentStatus: "process"
    },
    DONE_COOKING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    READY_FOR_PICKUP: {
        activeIndex: 1,
        currentStatus: "process"
    },
    DELIVERED: {
        activeIndex: 0,
        currentStatus: "success"
    },
    CANCELLED: {
        activeIndex: 3,
        currentStatus: "error"
    },
    CANCELLED_PAYMENT: {
        activeIndex: 3,
        currentStatus: "error"
    }
};

const tableOrderSteps = {
    PENDING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    WAITING: {
        activeIndex: 2,
        currentStatus: "process"
    },
    PROCESSING: {
        activeIndex: 1,
        currentStatus: "process"
    },
    DONE_PREPPING: {
        activeIndex: 1,
        currentStatus: "process"
    },
    CONFIRMED: {
        activeIndex: 1,
        currentStatus: "process"
    },
    DONE_COOKING: {
        activeIndex: 1,
        currentStatus: "process"
    },
    READY_FOR_PICKUP: {
        activeIndex: 0,
        currentStatus: "process"
    },
    DELIVERED: {
        activeIndex: 0,
        currentStatus: "success"
    },
    CANCELLED: {
        activeIndex: 2,
        currentStatus: "error"
    },
    CANCELLED_PAYMENT: {
        activeIndex: 3,
        currentStatus: "error"
    }
};

const orderStatusSteps = {
    TABLE_SERVICE: tableOrderSteps,
    THIRD_PARTY: thirdPartySteps,
    DELIVERY: deliveryStepss,
    REGULAR: regularSteps
} as const;

export enum DeliveryType {
    THIRD_PARTY = "THIRD_PARTY",
    DELIVERY = "DELIVERY",
    REGULAR = "REGULAR",
    TABLE_SERVICE = "TABLE_SERVICE"
}

export const getDeliveryType = (order: SwishOrderResponse): DeliveryType => {
    const isHomeDeliveryOrder = order.eatingOption === EatingPreference.HOME_DELIVERY;
    if (isHomeDeliveryOrder && !!order.deliveryCreateResponse) {
        return DeliveryType.THIRD_PARTY;
    } else if (isHomeDeliveryOrder) {
        return DeliveryType.DELIVERY;
    } else if (order.tableMeta) {
        return DeliveryType.TABLE_SERVICE;
    } else {
        return DeliveryType.REGULAR;
    }
};

const getStatusToUse = (
    onlineOrderStatusTimestamps: SwishOrderResponse["onlineOrderStatusTimestamps"],
    kitchenOrderStatusTimestamps: { status: string; timestamp: string }[],
    eatingPreferenceSteps: any
) => {
    const stepsKeys = Object.keys(eatingPreferenceSteps).reverse();
    const allStatuses = [...onlineOrderStatusTimestamps, ...kitchenOrderStatusTimestamps].map(item =>
        "status" in item ? item.status : item.onlineOrderStatus
    );
    const latestStatus = allStatuses[allStatuses.length - 1];
    const isLastProcessing = latestStatus === onlineOrderStatus.PROCESSING;
    return isLastProcessing ? latestStatus : stepsKeys.find(stepsKey => allStatuses.includes(stepsKey));
};

export const Order: React.FC<Props> = ({ kitchenOrderStatusTimestamps, order, pickupDates, orderDenyReason }) => {
    const { selectedShop } = useQopla();
    const { translate, userLanguage, translateWithArgument } = useLanguage();
    const { resetFoodOptions, removeClientInfo } = useOnline();
    const { table } = useOnlineStore();

    const isQuickServeTable = table?.id === selectedShop?.settings?.onlineSettings?.qrQuickServe?.qrPickupOptionTableId;
    const isDisableTableService =
        (!!order.tableMeta && selectedShop?.settings?.onlineSettings?.disableTableService) ?? false;

    const orderSteps = getOrderSteps(order);
    const deliveryType = getDeliveryType(order);

    const lastIndex = orderSteps.length - 1;
    const latestStatus = kitchenOrderStatusTimestamps
        ? kitchenOrderStatusTimestamps[kitchenOrderStatusTimestamps.length - 1]?.status
        : KitchenOrderStatus.WAITING;

    const [steps, setSteps] = useState<Steps>(() => ({
        activeIndex: lastIndex,
        currentStatus: "process"
    }));
    const [formattedPickupTime, setFormattedPickupTime] = useState<string>(() =>
        pickupTimeText(order, isDisableTableService, latestStatus, userLanguage)
    );

    const { emptyCart, activeOrderWindowId } = useOrderWindowsStore();

    const handleSetSteps = (activeIndex: number, currentStatus: "process" | "error" | "success" = "process") => {
        setSteps({
            activeIndex,
            currentStatus
        });
    };

    useEffect(() => {
        const eatingPreferenceSteps = orderStatusSteps[deliveryType];
        const statusToUse = getStatusToUse(
            order.onlineOrderStatusTimestamps,
            kitchenOrderStatusTimestamps,
            eatingPreferenceSteps
        );

        //@ts-ignore
        setSteps(eatingPreferenceSteps[statusToUse]);

        if (deliveryType === DeliveryType.THIRD_PARTY) {
            if (!!order.deliveryCreateResponse && !!order.deliveryCreateResponse.delivered) {
                handleSetSteps(0, "success");
            }
        }

        const hasDeclined = declinedOnlineOrderStatuses.includes(order.onlineOrderStatus);

        if (hasDeclined) {
            return handleSetSteps(lastIndex - 1, "error");
        }

        if (order.onlineOrderStatus === onlineOrderStatus.CONFIRMED) {
            emptyCart(activeOrderWindowId);
            resetFoodOptions();
            removeClientInfo();
        }
    }, [kitchenOrderStatusTimestamps]);

    useInterval(
        () => setFormattedPickupTime(pickupTimeText(order, isDisableTableService, latestStatus, userLanguage)),
        60000
    );

    const statusColor =
        failedOnlineOrderStatuses.includes(latestStatus) || failedOnlineOrderStatuses.includes(order.onlineOrderStatus)
            ? "#F56565"
            : "#084625";
    //for quickServe remove tableId from searchParams,
    //to not pre-select eating options when going back
    const params = { tableId: isQuickServeTable ? undefined : order.tableMeta?.id, qr: order.qr ? "1" : "0" };
    const searchParams = qs.stringify(params);

    const isHomeDelivery = order.eatingOption === EatingPreference.HOME_DELIVERY;

    const header = isHomeDelivery
        ? translate("homeDelivery")
        : !isDisableTableService && !!order.tableMeta
        ? translate("serving")
        : translate("pickup");

    const deniedOrderMessage = orderDenyReason
        ? translateWithArgument("deniedOrderWithArg", translate(orderDenyReason as Languagekey))
        : translate(formatDeniedOrderMsg(order.onlineOrderStatus));

    return (
        <>
            {/** @ts-ignore */}
            <Helmet>
                <title>Qopla | {translate("orderStatus")}</title>
            </Helmet>
            <Flex direction="column" align="center" justify="center" height="100%" bg="gray.100">
                <Card height="initial">
                    <CardHeader>
                        <OrderHeader order={order} statusColor={statusColor} />
                    </CardHeader>
                    <CardBody>
                        <NewGrid
                            templateColumns={["1fr", "1fr 1fr"]}
                            templateAreas={[
                                `"pickup" "status" "receipt" "contact"`,
                                `"pickup receipt""status receipt""contact receipt"`
                            ]}
                            gap={6}
                        >
                            <NewGrid area="pickup">
                                <Flex direction="column" justify="center">
                                    <Card>
                                        <CardHeader>
                                            <Header as="h3" size="md" textAlign="center">
                                                {header}
                                            </Header>
                                            {pickupDates && pickupDates.length > 1 && (
                                                <LanguageHeader
                                                    tid="pickupTimePostedPoned"
                                                    as="h3"
                                                    textAlign="center"
                                                    color="red.500"
                                                />
                                            )}
                                        </CardHeader>
                                        <CardBody
                                            style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
                                        >
                                            <Header
                                                as="h2"
                                                textAlign="center"
                                                size="xl"
                                                color={
                                                    formattedPickupTime === translate("delayed") ||
                                                    [
                                                        onlineOrderStatus.OFFLINE_AUTO_DENIED,
                                                        onlineOrderStatus.DECLINED,
                                                        onlineOrderStatus.FAILED_PAYMENT,
                                                        onlineOrderStatus.CANCELLED,
                                                        onlineOrderStatus.DENIED,
                                                        onlineOrderStatus.CANCELLED_PAYMENT
                                                    ].includes(order.onlineOrderStatus)
                                                        ? "orange.700"
                                                        : "gray.900"
                                                }
                                            >
                                                {formattedPickupTime}
                                            </Header>
                                            {pickupDates && pickupDates.length > 3 && (
                                                <Popup
                                                    position="top center"
                                                    content={pickupDates
                                                        .slice(0, pickupDates.length - 3)
                                                        .map((item: any, index: any) => {
                                                            const isLast =
                                                                pickupDates.indexOf(item) === pickupDates.length - 1;
                                                            return (
                                                                <Text
                                                                    key={`${index}_${item}`}
                                                                    textAlign="center"
                                                                    as={!isLast ? "s" : undefined}
                                                                >
                                                                    {item}
                                                                </Text>
                                                            );
                                                        })}
                                                    on="click"
                                                    pinned
                                                    trigger={
                                                        <Text
                                                            textAlign="center"
                                                            color="newPrimary"
                                                            fontSize={8}
                                                            cursor="pointer"
                                                        >
                                                            &hellip;
                                                        </Text>
                                                    }
                                                />
                                            )}
                                            {pickupDates &&
                                                !!pickupDates.length &&
                                                pickupDates
                                                    .slice(pickupDates.length - 3 < 0 ? 0 : pickupDates.length - 3)
                                                    .map((item: any, index: any) => {
                                                        const isLast =
                                                            pickupDates.indexOf(item) === pickupDates.length - 1;
                                                        return (
                                                            <Text
                                                                key={`${index}_${item}`}
                                                                textAlign="center"
                                                                fontSize={isLast ? "lg" : "md"}
                                                                as={!isLast ? "s" : undefined}
                                                            >
                                                                {item}
                                                            </Text>
                                                        );
                                                    })}
                                        </CardBody>
                                    </Card>
                                </Flex>
                            </NewGrid>
                            <NewGrid area="status">
                                <Flex flexDirection="column" justifyContent="center">
                                    <Card>
                                        <CardHeader>
                                            <LanguageHeader tid="status" as="h3" size="md" textAlign="center" />
                                        </CardHeader>
                                        <CardBody>
                                            <Steps
                                                themeColor={steps?.currentStatus === "success" ? "green" : "blue"}
                                                isReversed
                                                {...steps}
                                            >
                                                {orderSteps.map(({ titleKey, status }) => {
                                                    return (
                                                        <StepItem
                                                            key={titleKey}
                                                            title={translate(titleKey as Languagekey)}
                                                            description={getOrderStepTimestamp(
                                                                status,
                                                                kitchenOrderStatusTimestamps,
                                                                order.onlineOrderStatusTimestamps
                                                            )}
                                                        />
                                                    );
                                                })}
                                            </Steps>
                                            {failedOnlineOrderStatuses.includes(order.onlineOrderStatus) && (
                                                <Flex margin="1em 0 0 0" justifyContent="center" flexDirection="column">
                                                    <Text textAlign="center">
                                                        {translate("errorMessage")}: {deniedOrderMessage}
                                                    </Text>
                                                    {selectedShop && selectedShop && selectedShop.publicId && (
                                                        <Text textAlign="center">
                                                            <Link
                                                                href={`/restaurant/${formatShopUrlName(
                                                                    order.shopName
                                                                )}/${selectedShop.publicId}/checkout`}
                                                                color="#F56565"
                                                            >
                                                                {translate("tryAgain")}
                                                            </Link>
                                                        </Text>
                                                    )}
                                                </Flex>
                                            )}
                                        </CardBody>
                                    </Card>
                                </Flex>
                            </NewGrid>
                            <NewGrid area="receipt">
                                <OrderSummary order={order} />
                            </NewGrid>
                            <NewGrid area="contact">
                                <Card>
                                    <CardHeader>
                                        <LanguageHeader
                                            tid="restaurantContactInformation"
                                            as="h3"
                                            size="md"
                                            textAlign="center"
                                        />
                                    </CardHeader>
                                    <CardBody>
                                        <Flex flexDirection="column">
                                            <Text textAlign="center">
                                                {translate("phone")}: {order.contactInformation.phoneNumber}
                                            </Text>
                                            <Text textAlign="center">
                                                {translate("address")}: {order.contactInformation.addressLine}
                                            </Text>
                                        </Flex>
                                        {selectedShop && selectedShop && selectedShop.publicId && (
                                            <Flex justify="center">
                                                <LinkButton
                                                    to={`/restaurant/${formatShopUrlName(order.shopName)}/${
                                                        selectedShop.publicId
                                                    }/order?${searchParams}`}
                                                    marginTop={6}
                                                    themeColor="green"
                                                    backgroundColor="newPrimary"
                                                    color="newPrimaryFont"
                                                >
                                                    {translate("backToRestaurant")}
                                                </LinkButton>
                                            </Flex>
                                        )}
                                    </CardBody>
                                </Card>
                            </NewGrid>
                        </NewGrid>
                    </CardBody>
                </Card>
            </Flex>
        </>
    );
};
