import React from "react";
import { ExecutionResult } from "graphql";
import { useNavigate } from "react-router-dom";

import { Modal, ModalBody, ModalHeader, ModalActions } from "Molecules";
import { Button } from "Atoms";
import { modalNames } from "Constants";
import { IModalContext, useLanguage } from "Providers";

type Props = {
    cancelMutation: () => Promise<ExecutionResult<Boolean>>;
};

export const ConfirmCancelPayment: React.FC<IModalContext<Props>> = ({
    closeModal,
    modalContent: { cancelMutation }
}) => {
    const { translate } = useLanguage();
    const navigate = useNavigate();

    const closeConfirmCancelModal = () => closeModal(modalNames.CONFIRM_CANCEL_PAYMENT_MODAL);

    const handleCancelOrder = async () => {
        await cancelMutation();
        closeConfirmCancelModal();
        navigate(-1);
    };

    return (
        <Modal open={true} size="xs" width="85%">
            <ModalHeader>{translate("cancelPurchase")}</ModalHeader>
            <ModalBody>{translate("promptCancelPurchase")}</ModalBody>
            <ModalActions>
                <Button onClick={closeConfirmCancelModal}>{translate("no")}</Button>
                <Button themeColor="red" ml={3} onClick={handleCancelOrder}>
                    {translate("confirmCancelPurchase")}
                </Button>
            </ModalActions>
        </Modal>
    );
};
