import React, { useEffect } from "react";
import { ExecutionResult } from "react-apollo";
import { FaFileInvoiceDollar } from "@react-icons/all-files/fa/FaFileInvoiceDollar";
import { FaCreditCard } from "@react-icons/all-files/fa/FaCreditCard";

import { Flex, Header, Box, Dot } from "Atoms";
import { NETAXEPT_PAYMENT_RESULT, PaymentMethod } from "Constants";
import { useLanguage } from "Providers";
import { LoadingMessage } from "TempUtils";
import { SwishExpressPayment } from "./Swish/SwishExpressPayment";
import { SwishOnlinePayment } from "./Swish/SwishOnlinePayment";

type IPaymentTypeWrapper = {
    cancelMutation: () => Promise<ExecutionResult<Boolean>>;
    goBack: () => void;
    qrCode?: string;
    token?: string;
    isExpress: boolean;
    onSwitchIndex: (index: number) => void;
};

type InvoicePaymentProps = IPaymentTypeWrapper;
type CardPaymentProps = {
    responseCode: string | null;
} & Omit<IPaymentTypeWrapper, "onSwitchIndex">;

export const InvoicePayment: React.FC<InvoicePaymentProps> = ({ onSwitchIndex }) => {
    const { translate } = useLanguage();
    useEffect(() => {
        const id = setTimeout(() => {
            onSwitchIndex(2);
        }, 4000);
        return () => clearTimeout(id);
    }, []);

    return (
        <Flex direction="column" align="center" justify="center" height="100%">
            <Flex direction="column" p={8} height="auto" boxShadow="lg" backgroundColor="white" borderRadius="md">
                <Flex justify="center" mb={4}>
                    <Box as={FaFileInvoiceDollar} size="60px" />
                </Flex>
                <Flex flex={1} direction="column">
                    <Header as="h2" size="lg" textAlign="center">
                        {translate("processingOrder")}
                        <Dot>.</Dot>
                        <Dot>.</Dot>
                        <Dot>.</Dot>
                    </Header>
                </Flex>
            </Flex>
        </Flex>
    );
};

export const CardPayment: React.FC<CardPaymentProps> = ({ responseCode }) => {
    const { translate } = useLanguage();
    const text =
        responseCode && responseCode === NETAXEPT_PAYMENT_RESULT.SUCCESSFUL
            ? translate("processingOrder")
            : translate("abortingOrder");

    return (
        <Flex direction="column" align="center" justify="center" height="100%">
            <Flex direction="column" p={8} height="auto" boxShadow="lg" backgroundColor="white" borderRadius="md">
                <Flex justify="center" mb={4}>
                    <Box as={FaCreditCard} size="60px" />
                </Flex>
                <Flex flex={1} direction="column">
                    <Header as="h2" size="lg" textAlign="center">
                        {text}
                        <Dot>.</Dot>
                        <Dot>.</Dot>
                        <Dot>.</Dot>
                    </Header>
                </Flex>
            </Flex>
        </Flex>
    );
};

////////////////////////////////////////////

export type SwishPaymentProps = Omit<IPaymentTypeWrapper, "onSwitchIndex">;

export const SwishPayment: React.FC<SwishPaymentProps> = (props: SwishPaymentProps) => {
    const isExpress = !!props.isExpress;
    if (isExpress) {
        return <SwishExpressPayment {...props} />;
    } else {
        return <SwishOnlinePayment {...props} />;
    }
};

////////////////////////////////////////////

type PaymentTypeWrapperProps = {
    responseCode: string | null;
    type: PaymentMethod | null;
} & IPaymentTypeWrapper;

export const PaymentTypeWrapper: React.FC<PaymentTypeWrapperProps> = ({
    type,
    onSwitchIndex,
    responseCode,
    ...rest
}) => {
    if (type === PaymentMethod.INVOICE) {
        return <InvoicePayment onSwitchIndex={onSwitchIndex} {...rest} />;
    } else if (type === PaymentMethod.CARD) {
        return <CardPayment responseCode={responseCode} {...rest} />;
    } else if (type === PaymentMethod.SWISH) {
        return <SwishPayment {...rest} />;
    } else {
        return <LoadingMessage />;
    }
};
