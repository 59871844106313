import React from "react";
import { isMobileSafari } from "react-device-detect";

import { Flex, NewButton, Image, Text } from "Atoms";
import { SwishLogoPng } from "Assets";
import { modalNames } from "Constants";
import { useLanguage, useModal } from "Providers";
import { LanguageHeader, LanguageText } from "Components";
import { ExpressOnlineCheckoutContainer, ExpressOnlineWrapper } from "../../../checkout/components/ExpressCheckoutForm";
import { SwishPaymentProps } from "../PaymentTypeWrapper";

export const SwishExpressPayment = ({ qrCode, cancelMutation, goBack }: SwishPaymentProps) => {
    const { openModal } = useModal();
    const { translate } = useLanguage();
    return (
        <ExpressOnlineCheckoutContainer>
            <ExpressOnlineWrapper>
                <Flex direction="column" align="center" justify="center" height="100%" width="100%">
                    <Flex
                        direction="column"
                        p={8}
                        height="auto"
                        boxShadow="lg"
                        backgroundColor="white"
                        borderRadius="md"
                    >
                        <Flex height="100px" mb={4} justify="center">
                            <Image src={SwishLogoPng} height="100%" objectFit="contain" />
                        </Flex>
                        <Flex flex={1} direction="column" mb={8}>
                            {!!qrCode ? (
                                <Flex direction="column" align="center">
                                    <Text fontSize="2xl" mt={2} mb={0}>
                                        <LanguageText tid="openSwishOne" as="span" />
                                        <LanguageText tid="theApp" as="span" fontWeight="bold" />
                                        <LanguageText tid="openSwishTwo" as="span" />
                                    </Text>
                                    <Image src={`data:image/svg+xml;base64,${qrCode}`} alt="" width="3xs" />
                                </Flex>
                            ) : (
                                <LanguageHeader tid="openSwishOnMobile" as="h3" size="lg" textAlign="center" />
                            )}
                        </Flex>
                        <Flex justify="center" align="center" direction="column">
                            <NewButton
                                themeColor="red"
                                color="red.500"
                                my={5}
                                variant="outline"
                                onClick={() =>
                                    isMobileSafari
                                        ? openModal(modalNames.CONFIRM_CANCEL_PAYMENT_MODAL, { cancelMutation })
                                        : goBack()
                                }
                            >
                                {translate("cancelOrder")}
                            </NewButton>
                            <LanguageText tid="receiverQopla" as="span" textAlign="center" m={0} />
                        </Flex>
                    </Flex>
                </Flex>
            </ExpressOnlineWrapper>
        </ExpressOnlineCheckoutContainer>
    );
};
