import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";

import { Box, Button, Center, Flex, Header, Text } from "Atoms";
import { getGroupOnlineOrderUrl, GroupOnlineOrderOverviewParams } from "Utils";
import { useLanguage } from "Providers";
import { useOrderWindowSocketStore } from "Stores";
import { OrderWindow } from "Types";
import { SwishOrderResponse } from "../OrderStatusContainer";
import { GroupOnlineOrderConfirmationSummary } from "../../groupOnlineOrder/components";

type Props = {
    order: SwishOrderResponse;
};

type Location = {
    nickname: string;
};

export const GroupOnlineOrderConfirmation: React.FC<Props> = ({ order }) => {
    const [orderWindow, setOrderWindow] = useState<OrderWindow | null>(null);

    const params = useParams<GroupOnlineOrderOverviewParams>();
    const navigate = useNavigate();
    const location = useLocation();
    const state = location.state as Location;

    const { translate } = useLanguage();

    const { removeGroupOrderLocalStorage, handleFetchOrderWindowById } = useOrderWindowSocketStore();

    const groupOnlineOrderUrl = getGroupOnlineOrderUrl(params as GroupOnlineOrderOverviewParams);

    useEffect(() => {
        if (order) {
            handleFetchOrderWindowById(params.orderWindowId!, false).then(orderWindow => {
                if (!!orderWindow) {
                    setOrderWindow(orderWindow);
                    removeGroupOrderLocalStorage();
                }
            });
        }
    }, [order, params.orderWindowId]);

    return (
        <Flex direction="column">
            <Center h="auto" direction="column" mb={4}>
                <Header size="2xl" mb={2}>
                    {translate("thanks")}!
                </Header>
                <Text fontSize="lg">{translate("hopeOrderWasTasty")}</Text>
            </Center>
            <Box flex="1">
                <GroupOnlineOrderConfirmationSummary
                    order={order}
                    orderWindow={orderWindow}
                    nickname={state.nickname}
                />
            </Box>
            <Box mt={6}>
                <Button
                    fullWidth
                    size="lg"
                    themeColor="blue"
                    rounded="lg"
                    onClick={() => navigate(groupOnlineOrderUrl)}
                    mb={4}
                >
                    {translate("orderAgain")}
                </Button>
                <Text fontSize="xl" textAlign="center">
                    <Text as="span">Powered by </Text>
                    <Text as="span" fontFamily="qopla" fontSize="2xl" color="newPrimary">
                        Qopla
                    </Text>
                </Text>
            </Box>
        </Flex>
    );
};
